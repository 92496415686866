<template>
  <div>
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div class="c-d-flex ui-w-full ui-mb-6">
          <c-skeleton-block
            v-if="loading"
            width="9rem"
            class="ui-mt-2"
          />
          <div
            v-else
            class="c-text-h3"
          >
            Privacidade
          </div>
        </div>

        <div
          v-for="area of anonymizedAreas.filter(a => !a.hidden)"
          :key="area.key"
          class="c-d-flex ui-w-full ui-mb-6"
        >
          <div class="ui-w-10 ui-pt-1">
            <c-skeleton-block
              v-if="loading"
              width="100%"
            />
            <c-toggle
              v-else
              :value="!area.anonymous"
              @input="() => updateAnonymizedAreas(area)"
            />
          </div>
          <div class="c-d-flex ui-flex-col ui-flex-1 ui-ml-6">
            <c-skeleton-block
              v-if="loading"
              class="ui-my-1"
              width="min(100%, 10rem)"
              height="1.5rem"
            />
            <div
              v-else
              class="c-text-subtitle"
            >
              {{ area.name }}
            </div>
            <c-skeleton-block
              v-if="loading"
              width="min(100%, 30rem)"
              height="1.25rem"
            />
            <div
              v-else
              class="c-text-b2 c-text-gray-300"
            >
              {{ area.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CSkeletonBlock, CToggle } from '@estrategiahq/coruja-web-ui';
import { UserStoreKeys } from '@/store/modules/user';

export default {
  components: {
    CSkeletonBlock,
    CToggle,
  },
  computed: {
    ...mapGetters({
      user: UserStoreKeys.GET_LOGGED_USER,
      loading: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  created () {
    this.loadPrivacyData();
  },
  data() {
    return {
      anonymizedAreas: [
        {
          key: 'ranking_simulados',
          name: 'Ranking',
          description: 'Exibir meu perfil nos rankings de questões e simulados',
          anonymous: false,
        },
        {
          key: 'forum_questoes',
          name: 'Fórum de questões',
          description: 'Exibir meu perfil no fórum de alunos',
          anonymous: false,
          hidden: !this.$isPreview('fase2'),
        },
        {
          key: 'forum_cursos',
          name: 'Fórum de cursos',
          description: 'Exibir meu perfil no fórum de dúvidas',
          anonymous: false,
          hidden: !this.$isPreview('fase2'),
        },
      ],
    };
  },
  methods: {
    async loadPrivacyData() {
      await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);

      this.anonymizedAreas.forEach(area => {
        area.anonymous = this.user.anonymizedAreas.some(key => key === area.key);
      });
    },
    async updateAnonymizedAreas (selectedArea) {
      selectedArea.anonymous = !selectedArea.anonymous;

      await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER_PRIVACY, {
        anonymizedAreas: this.anonymizedAreas.filter(a => a.anonymous).map(a => a.key),
      });

      this.loadPrivacyData();
    },
  },
};
</script>
